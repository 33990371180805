import styles from './Services.module.css';

import Bathroom from '../../../assets/Bathroom.jpg';
import HouseAddition from '../../../assets/HouseAddition.jpg';
import KitchenRemodel from '../../../assets/KitchenRemodel.png';
import Shelves from '../../../assets/Shelves.jpg';
import AccentWalls from '../../../assets/AccentWalls.jpg';
import Carpentry from '../../../assets/Carpentry.jpg';
import Cabinets from '../../../assets/Cabinets.jpg';
import Doors from '../../../assets/Doors.jpg';
import Trim from '../../../assets/Trim.jpg';

const projects = [
    {
      id: 1,
      title: "Kitchen Remodeling",
      description: "Make your dream a reality. Add the island, new lightning, and retouch the cabinets ",
      imageUrl: KitchenRemodel
    },
    {
      id: 3,
      title: "Bathroom Remodeling",
      description: "Enhance your bathroom for enjoyment and relaxation",
      imageUrl: Bathroom
    },
    {
      id: 4,
      title: "Additions",
      description: "Add a new room, living space, kitchen, or closet.",
      imageUrl: HouseAddition
    },
    {
      id: 5,
      title: "Custom carpentry",
      description: "Tailored woodwork that brings your design vision to life with expert craftsmanship",
      imageUrl: Carpentry
    },
    {
      id: 6,
      title: "Trim",
      description: "Enhance your interiors with expertly installed trim, adding character and elegance to every room",
      imageUrl: Trim
    },
    {
      id: 7,
      title: "Door/lockout installations",
      description: "Secure your home with our professional door and lock installations, combining safety with style",
      imageUrl: Doors
    },
    {
      id: 8,
      title: "Accent walls",
      description: "Create striking focal points with custom accent walls that reflect your personality and elevate your space",
      imageUrl: AccentWalls
    },
    {
      id: 9,
      title: "Shelves",
      description: "Optimize storage with custom shelves designed to seamlessly blend with your décor",
      imageUrl: Shelves
    },
    {
      id: 10,
      title: "Cabinet installations",
      description: "Upgrade your space with expertly installed cabinets, tailored to your style and needs",
      imageUrl: Cabinets
    },
  ];


const Services = () => {
    return (
    <div className={styles.container}>
    <h1 id="services">Services Provided</h1>
    <div className={styles.projectGrid}>
      {projects.map((project) => (
        <div key={project.id} className={styles.projectCard}>
          <img src={project.imageUrl} alt={project.title} className={styles.projectImage} />
          <div className={styles.projectDetails}>
            <h3 className={styles.projectTitle}>{project.title}</h3>
            <p className={styles.projectDescription}>{project.description}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
    );
}

export default Services;