import React from 'react';
import styles from './AboutUs.module.css';
import Us from '../../../assets/AboutUs.png';

const AboutUs = () => {
  return (
    <>
      <div id='about' className={styles.aboutUsSection}>
        <h2 className={styles.heading}>About Us</h2>
        <img src={Us} alt="About Us" className={styles.aboutUsImage} />
        <div className={styles.aboutUsContent}>
          <p className={styles.aboutUsParagraph}>Our company has been transforming homes and lives for over a decade. We pride ourselves on delivering exceptional quality and service in every project we undertake.</p>
          <p className={styles.aboutUsParagraph}>We specialize in a wide range of home renovation services, including kitchen remodeling, bathroom enhancements, and custom carpentry. Our team of skilled professionals is dedicated to making your vision a reality.</p>
          <p className={styles.aboutUsParagraph}>Customer satisfaction is our top priority. We work closely with you from the initial consultation to the final walkthrough to ensure that your expectations are met and exceeded. Let us help you create the home of your dreams.</p>
        </div>
      </div>
    </>
  );
};

export default AboutUs;