import React from 'react';
import styles from './Home.module.css';
import Services from '../Services/Services';

import backgroundImage from '../../../assets/Background.jpg';
import AboutUs from '../AboutUs/AboutUs';
import Contact from '../Contact/Contact';

const Home = () => {
  return (
    <div>
      <div className={styles.hero} style={{ backgroundImage: `url(${backgroundImage})` }}>
        {/* This div is intentionally left empty to serve as a background image section */}
      </div>
      <div className={styles.heroContent}>
        <h1>Redefine your living</h1>
        <p>Quality craftsmanship, timeless design.</p>
      </div>
      <Services />
      <AboutUs />
      <Contact />
    </div>
  );
};

export default Home;