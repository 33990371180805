import React from 'react';

const HomeRenovations = () => {
  return (
    <div>
      <h1>Home Renovations</h1>
      <p>Details about home renovations...</p>
    </div>
  );
};

export default HomeRenovations;