import React from 'react';
import styles from './Contact.module.css';

import Project from '../../../assets/Contact.jpg';

const Contact = () => {
  return (
    <div id="contact" className={styles.container}>
      <h1 className={styles.title}>Contact Us for a Custom Bid</h1>
      <div className={styles.contactInfo}>
        <div className={styles.contactItem}>
          <div className={styles.contactText}>
            <h2>Email</h2>
            <p className={styles.email}>moralestrim23@yahoo.com</p>
            <h2>Phone</h2>
            <p className={styles.phone}>512-576-2311</p>
          </div>
          <div className={styles.contactImage}>
            <img src={Project} alt="Construction" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
