import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/common/Navbar/Navbar';
import Home from './components/screens/Home/Home';
import AboutUs from './components/screens/AboutUs/AboutUs';
import Contact from './components/screens/Contact/Contact';
import KitchenRemodels from './components/screens/KitchenRemodels/KitchenRemodels';
import BathroomRemodels from './components/screens/BathroomRemodels/BathroomRemodels';
import HomeRenovations from './components/screens/HomeRenovations/HomeRenovations';

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services/kitchen-remodels" element={<KitchenRemodels />} />
        <Route path="/services/bathroom-remodels" element={<BathroomRemodels />} />
        <Route path="/services/home-renovations" element={<HomeRenovations />} />
      </Routes>
    </Router>
  );
};

export default App;